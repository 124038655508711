<template>
  <v-menu
    :close-on-content-click="closeOnContentClick"
    bottom
    width="300"
    offset-y
    z-index="1"
    allow-overflow
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        hide-details
        height="10"
        class="caption"
        prepend-inner-icon="mdi-filter"
        placeholder="Filter Berdasarkan"
        outlined
        dense
        readonly
        v-bind="attrs"
        @click="closeOnContentClick = false"
        v-on="on"
      ></v-text-field>
    </template>
    <v-list
      width="300"
      min-height="150"
      max-height="400"
      class="overflow-auto"
    >
      <section>
        <v-list-item>
          <v-list-item-title class="caption font-weight-bold">{{$_strings.oracleCost.TYPE}}</v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-content class="pt-0 mt-0">
            <v-text-field
              dense
              outlined
              class="caption"
              v-model="filters.name"
              hide-details
            />
          </v-list-item-content>
        </v-list-item>
      </section>
      <section>
        <v-list-item>
          <v-list-item-title class="caption font-weight-bold">{{$_strings.oracleCost.STATUS}}</v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-content class="pt-0 mt-0">
            <v-select
              :items="status"
              :menu-props="{ bottom: true, offsetY: true }"
              v-model="filters.isActive"
              class="caption mb-4 mr-4"
              dense
              hide-details
              flat
            />
          </v-list-item-content>
        </v-list-item>
      </section>
      <section class="pl-2 pr-2">
        <v-btn
          depressed
          color="primary"
          block
          @click="setFilter"
        >
          {{$_strings.common.APPLY}}
        </v-btn>
        <v-btn
          depressed
          class="mt-2 white--text"
          color="red"
          block
          @click="closeOnContentClick = true"
        >
          {{$_strings.common.CLOSE}}
        </v-btn>
      </section>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'fiter-oracle-cost',
  data() {
    return {
      closeOnContentClick: false,
      filters: {
        name: this.$route.query.name || '',
        isActive: this.$route.query.isActive || '',
      },
      status: [
        {
          text: 'Semua Status',
          value: '',
        },
        {
          text: 'Aktif',
          value: 'true',
        },
        {
          text: 'Tidak Aktif',
          value: 'false',
        },
      ],
    };
  },
  methods: {
    setFilter() {
      this.closeOnContentClick = true;
      this.$emit('setNewFilters', this.filters);
    },
  },
};
</script>
