<template>
  <OrcacleCost />
</template>

<script>

import OrcacleCost from '../components/OracleCost';

export default {
  name: 'OracelCost-Page',
  components: {
    OrcacleCost,
  },
};
</script>
