<template>
  <v-container fluid>
    <v-row
      dense
      align="center"
      class="mb-4"
    >
      <v-col cols="auto">
        <!-- <v-btn
          elevation="1"
          color="primary"
          dark
          @click="fetchList"
        >
          {{$_strings.oracleCost.REFRESH_DATA_ORACLE}}
        </v-btn> -->
      </v-col>
      <v-col
        cols="12"
        sm="8"
        class="ml-sm-auto"
      >
        <v-row
          dense
          justify="end"
        >
          <v-col cols="auto">
            <FilterList
              @setNewFilters="setNewFilters"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              dense
              hide-details
              outlined
              class="caption"
              v-model="search"
              @keyup.enter="setNewFilters({ name: search })"
              :placeholder="$_strings.oracleCost.SEARCH_TEXT"
            >
              <template v-slot:prepend-inner>
                <v-icon @click="setNewFilters({ name: search })">
                  mdi-magnify
                </v-icon>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <List
      :key="key_list"
      :filters="filters"
      @showDialogAdditionalCost="showDialogAdditionalCost"
    />
  </v-container>
</template>

<script>
import List from './List.vue';
import FilterList from './FilterList.vue';

export default {
  name: 'index-oracle-cost',
  components: {
    List,
    FilterList,
  },
  data() {
    return {
      key_list: 0,
      search: this.$route.query.name || '',
      filters: {
        isActive: this.$route.query.isActive || '',
        code: this.$route.query.code || '',
      },
    };
  },
  methods: {
    fetchList() {
      this.key_list += 1;
    },
    setNewFilters(newFilters) {
      const newQuery = {
        ...this.$route.query,
        ...newFilters,
        page: 1,
      };
      if (JSON.stringify(newQuery).replace(/["]/g, '') !== JSON.stringify(this.$route.query).replace(/["]/g, '')) {
        this.$router.replace({
          query: newQuery,
        });
        this.filters = {
          ...this.filters,
          ...newFilters,
        };
      }
      this.fetchList();
    },
    showDialogAdditionalCost(item) {
      this.$refs.dialogAddAdditionalCost.dialog = true;
      this.$refs.dialogAddAdditionalCost.form = { ...item };
    },
  },
};
</script>
