<template>
  <v-data-table
    item-key="id"
    :loading="isLoading"
    :headers="displayedHeader"
    :items="oracleCostListItem"
    :server-items-length="totalItems"
    :options.sync="pagination"
    :footer-props="{
      showCurrentPage: true,
      showFirstLastPage: true,
      'items-per-page-options': $_item_per_page
    }"
  >
    <template v-slot:[`item.isActive`]={item}>
      <span>{{ item.isActive ? 'Aktif' : 'Tidak Aktif' }}</span>
    </template>
    <template v-slot:[`footer.page-text`]="props">
      <span>
        {{$_strings.oracleCost.LIST_PAGE}}
        <span v-if="oracleCostListItem.length">
          {{props.pageStart}}-{{props.pageStop}} of {{props.itemsLength}}
        </span>
      </span>
    </template>
  </v-data-table>
</template>

<script>
import axios from 'axios';
import {
  skipEmptyStringObject,
  handleSortBy,
  handlerPagination,
  defaultPagination,
} from '@/helper/commonHelpers';

const { CancelToken } = axios;
let source = CancelToken.source();

export default {
  name: 'list-oracle-cost',
  props: {
    filters: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    source = CancelToken.source();
  },
  beforeDestroy() {
    if (this.$route.path !== '/main/oracle-cost') source.cancel('CANCELED_BY_THE_USER');
  },
  data() {
    return {
      isLoading: false,
      headers: [
        {
          text: this.$_strings.oracleCost.ID,
          value: 'code',
        },
        {
          text: this.$_strings.oracleCost.TYPE,
          value: 'name',
        },
        {
          text: this.$_strings.oracleCost.DESC,
          value: 'description',
        },
        {
          text: this.$_strings.vaccine.STATUS,
          value: 'isActive',
        },
      ],
      oracleCostListItem: [],
      pagination: this.defaultPagination(),
      totalItems: 0,
    };
  },
  computed: {
    displayedHeader() {
      return this.headers.map((i) => ({
        ...i,
        class: 'white--text primary text-capitalize',
      }));
    },
  },
  watch: {
    pagination: {
      handler(newVal) {
        this.handlerPagination(this, newVal);
        this.fetchOracleCostList();
      },
      deep: true,
    },
  },
  methods: {
    defaultPagination,
    handlerPagination,
    handleSortBy,
    skipEmptyStringObject,
    toggleLoading(boolean) {
      if (this.oracleCostListItem.length > 0) {
        this.isLoading = false;
        return boolean ? this.$root.$loading.show() : this.$root.$loading.hide();
      }
      this.$root.$loading.hide();
      this.isLoading = boolean;
    },
    async fetchOracleCostList() {
      const {
        page, itemsPerPage, sortBy, sortDesc,
      } = this.pagination;
      const filters = skipEmptyStringObject({
        page: page - 1,
        size: itemsPerPage,
        sort: this.handleSortBy({ sortBy, sortDesc }),
        ...this.filters,
      });
      if (this.filters.name) filters.name = `qLike(${filters.name})`;
      try {
        this.toggleLoading(true);
        const result = await this.$_services.oracleCost.getListOracleCost(filters, source);
        this.oracleCostListItem = result.data.contents;
        this.totalItems = result.data.totalData;
      } finally {
        this.toggleLoading(false);
      }
    },
  },
};
</script>
